<template>
  <v-layout column>
    <v-flex xs12>
      <v-card flat>
        <v-card-title>
          <h3>Versionsinformation</h3>
        </v-card-title>
        <v-list>
          <v-list-tile
            :key="key"
            class="mb-4"
            v-for="(value, key) in infoTiles"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ value }}</v-list-tile-title>
              <v-list-tile-sub-title>{{ key }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import formatDate from 'date-fns/format'

export default {
  name: 'StatusStats',
  computed: {
    git() {
      /* eslint-disable no-undef */
      const date = formatDate(
        parseInt(VUE_APP_BUILD_TIME, 10),
        'DD.MM.YYYY (HH:mm:ss [Uhr])'
      )
      const branch = VUE_APP_GIT_BRANCH
      const version = VUE_APP_GIT_VERSION
      const sha = VUE_APP_GIT_COMMIT_HASH
      /* eslint-enable no-undef */
      return {
        date,
        branch,
        version,
        sha,
      }
    },
    infoTiles() {
      return {
        'Build-Zeitpunkt': this.git.date,
        Branch: this.git.branch,
        'Commit Hash': this.git.sha,
        Version: this.git.version,
      }
    },
  },
}
</script>

<style scoped></style>
